import React from 'react';
import { Flex, Box, Card, Text } from 'rebass';
import { useTranslation } from 'react-i18next';
import Helmet from "react-helmet"

import CustomerSupportLayout from './_layout';
import Page from '../../components/Page';
import ReadyToTalkBackground from '../../images/ready-to-talk-image@2x.jpg';

import Form, { Input } from '../../components/Form';
import { Button } from '../../components/Button';

const HeroPhrase = ({ children }) => (
  <Text
    m="0"
    mb={['-4px', '-5px', '-6px']}
    width={[1, 1, 0.8]}
    color="white"
    fontSize={['14px', '16px', '20px']}
    fontWeight="normal"
    lineHeight={[1.2, 1.2, 1.4]}
  >
    {children}
  </Text>
);

const HeroTitle = ({ children }) => (
  <Text
    pb={[2, 1, 1]}
    color="white"
    fontSize={['56px', '72px', '90px']}
    fontWeight="bold"
    lineHeight="0.84"
    letterSpacing="-3.38px"
  >
    {children}
  </Text>
);

const HeroHeader = () => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="row"
      marginTop={['31px', '31px', '38px']}
      marginLeft={['25px', '39px', '48px']}
    >
      <VerticalYellowLine />
      <Flex
        marginTop="4px"
        px={['20px', '24px', '30px']}
        flexWrap="wrap"
        flexDirection="column"
      >
        {t('support.24_7_support.content.title', {
          returnObjects: true,
        }).map((title, index) => (
          <HeroTitle key={'title' + (index + 1)}>{title}</HeroTitle>
        ))}
        <Box flex="1" />
        <HeroPhrase>{t('support.24_7_support.content.subtitle')}</HeroPhrase>
      </Flex>
    </Flex>
  );
};

const VerticalYellowLine = ({ children }) => (
  <Box
    m="0"
    py={['110px', '122px', '152px']}
    px={['4px', '4px', '5px']}
    bg="maize"
    css={{
      height: '0',
      borderRadius: 5,
      display: 'inline-block',
    }}
  />
);

const ReadyToTalkCard = () => {
  const { t } = useTranslation();
  
  return (
    <Flex flexWrap="wrap" pt={['25px', '36px', '44px']}>
      <Card
        id="readyToTalkBackground"
        height={['270px', '302px', '378px']}
        width={[1, 3 / 5, 2 / 3]}
        backgroundImage={'url(' + ReadyToTalkBackground + ')'}
        backgroundSize={['cover']}
        backgroundPosition={['center', 'right']}
        backgroundRepeat="no-repeat"
        borderRadius={['20px 20px 0px 0px', '20px 0px 0px 20px']}
      >
        <HeroHeader />
      </Card>

      <Card
        width={[1, 2 / 5, 1 / 3]}
        px={['25px', '20px', '22px']}
        pb={'25px'}
        bg="maize"
        borderRadius={['0px 0px 20px 20px', '0px 20px 20px 0px']}
      >
        <Text
          mt={['20px', '25px', '56px']}
          fontSize={['20px', '21px', '26px']}
          color="blues.peacock"
        >
          {t('support.24_7_support.content.please_sign_in')}
        </Text>
        {/*
        <Form>
          <Input
            compact={true}
            title={t('support.24_7_support.content.username')}
            name="username"
            // onChange={e => setUsername(e.target.value)}
          />
          <Input
            compact={true}
            title={t('support.24_7_support.content.email')}
            type="email"
            name="email"
            // onChange={e => setPassword(e.target.value)}
          />
          <Button marginTop="20px" width="100%">
            {t('support.24_7_support.content.button')}
          </Button>
        </Form>
        */}
      </Card>
    </Flex>
  );
};

const LiveSupportPage = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <CustomerSupportLayout
      subtitle={t('support.24_7_support.menu.title')}
      {...props}
    >
        <Helmet>
          <script type="text/javascript">
          {`
          var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
          (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://embed.tawk.to/5e6787468d24fc226586c835/default';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
          })();`}
        </script>

        </Helmet>
      <Page
        title={t('support.24_7_support.page.title')}
        subtitle={t('support.24_7_support.page.subtitle')}
      >
        <ReadyToTalkCard />
      </Page>
    </CustomerSupportLayout>
  );
};

export default LiveSupportPage;
